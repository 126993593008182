import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { apiService } from "../../services/apiService";
import CompaniesCard from "../../shared/CompaniesCard";
import Filters from "../../shared/Filters/Filters";
import CompanyResponse from "../../Models/CompanyResponse";
import SearchBanner from "../../components/Banner/SearchBanner";
import PageLoader from "../../shared/page-loader";

const Search: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [state, setState] = useState<CompanyResponse>({
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [searchParams] = useSearchParams();
  const searchKey = searchParams.get("searchkey") || "";
  const previousSearchKey = useRef(searchKey); // Ref to track previous searchKey
  const isApiCallInProgress = useRef(false);

  useEffect(() => {
    // Check if searchKey has changed
    if (previousSearchKey.current !== searchKey) {
      previousSearchKey.current = searchKey; // Update ref to the new searchKey
      setPageNumber(1); // Reset page number
      loadCompanyData(searchKey, 1); // Fetch data with the updated search key
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    // Fetch data when pageNumber changes
    loadCompanyData(searchKey, pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const loadCompanyData = (key: string, page: number) => {
    if (isApiCallInProgress.current) return; // Prevent duplicate calls
    isApiCallInProgress.current = true;
    setIsLoading(true);
    const queryParams = searchParams.toString();
    const endpoint = `Company?PageNumber=${page}&PageSize=8&OrderByColumn=name&OrderBy=ASC&${queryParams}`;

    apiService
      .get(endpoint)
      .then((response) => {
        setState((prevState) => ({
          ...response,
          items:
            page === 1
              ? response.items
              : [...prevState.items, ...response.items],
        }));
        setPageLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setPageLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        isApiCallInProgress.current = false; // Reset the API call flag
      });
  };

  const handleLoadMore = () => {
    setPageNumber((prev) => prev + 1); // Increment page number for loading more data
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <SearchBanner />
      <Filters />
      <main className="container">
        <section className="py-6">
          <h2 className="text-center sectionHeading">
            Getting the Most from Your Search
            <small>
              We have got the companies more relevant to your search!
            </small>
          </h2>

          <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-4 g-3">
            {state.items.length > 0 ? (
              state.items.map((companyData) => (
                <div key={companyData.id} className="col">
                  <CompaniesCard companyData={companyData} />
                </div>
              ))
            ) : (
              <p>No companies found</p>
            )}
          </div>

          {isLoading && <p>Loading...</p>}

          {!isLoading && state.hasNextPage && (
            <div className="d-grid gap-2 d-flex justify-content-center mt-5">
              <button className="btn btn-primary" onClick={handleLoadMore}>
                Explore More
              </button>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default Search;
